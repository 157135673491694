<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getDocumentsForSinging"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DocumentsForSign',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'number_document', text: this.$t('number'), sortable: false },
        { value: 'sailorFullName', text: this.$t('fullName'), sortable: false },
        { value: 'country', text: this.$t('country'), sortable: false },
        { value: 'type_document', text: this.$t('typeDoc'), sortable: false },
        { value: 'rank', text: this.$t('rank'), sortable: false },
        { value: 'position', text: this.$t('position'), sortable: false },
        { value: 'port', text: this.$t('port'), sortable: false },
        { value: 'date_start', text: this.$t('dateIssue'), sortable: false },
        { value: 'date_end', text: this.$t('dateTermination'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => {
            return ({ name: item.type_document?.id === 16 ? 'qualification-documents-proof-info' : 'qualification-documents-info',
              params: { id: item.sailor.id, documentID: item.id },
              query: { ...this.$route.query }
            })
          },
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.documents.listDocumentForSign,
      isLoading: state => state.documents.isLoading
    })
  },
  methods: {
    ...mapActions(['getDocumentsForSinging'])
  }
}
</script>
